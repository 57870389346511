import {getDateOfCertainFormat} from "@/helpers/date"

import Cookies from "vue-cookies";

export default {
    methods: {
        dateToString(date) {
            if(date === null) {
                return '';
            }

            if (!(date instanceof Date) || isNaN(date)) {
                console.error("invalid date " + date);
                return '';
            }

            const userLocale = navigator.language;
            const formattedDate = new Intl.DateTimeFormat(userLocale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              }).format(date);

              return formattedDate;

            
            //return date ? getDateOfCertainFormat(date, format) : ''
        },
        dateToFormat(date, format) {
            return date ? getDateOfCertainFormat(date, format) : ''
        },
        getUser() {
            try {
                let cookie = Cookies.get('jwt');

                if(cookie === null){
                    return null;
                }

                return JSON.parse(atob(cookie.token.split('.')[1]));
            }
            catch (e) {
                return null;
            }
        },
        hasPermission(permission){
            let user = this.getUser();

            if (user === null) {
                return false;
            }

            let permissions = user.permissions;
            return permissions.includes(permission);
        },
        hasRole(role){
            let user = this.getUser();
            if (user === null) {
                return false;
            }

            return user.roles === role;
        },
        handleErrorResponse(error){
            if(error.response.status === 400 && error.response.data.code === 'validation_error') {
                this.$toast.add({severity: 'error', summary: 'Data entry error', life: 3000});

                return;
            }
            else if(error.response.status === 400) {
                this.$toast.add({severity: 'error', summary: error.response.data, life: 3000});

                return;
            }

            throw error;
        },
        isBackoffice() {
            return window.application === 'backoffice';
        },
        getLocales(){
            if(this.hasPermission('multi_language_editor')){
                return ['en', 'ru'];
            }

            return ['en'];
        },
        isLocales(){
            return this.hasPermission('multi_language_editor');
        },
    }
}
